<template>
  <section>
    <header class="container custom-container bordercustom">
      <ul class="appbar mb-0">
        <li><v-icon @click="backBtn"> mdi-chevron-left </v-icon></li>
        <li class="text-center">Verify</li>
      </ul>
    </header>
    <v-container class="custom-container">
      <div class="boxauthoriation">
        <v-row>
          <v-col md="12">
            <div class="message">
              Please enter your OTP Code received on your regestered email.
            </div>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="form-section"
            >
              <v-row>
                <v-col md="12" cols="12" v-if="email == ''">
                  <v-text-field
                    v-model="email"
                    label="Email Address"
                    :rules="emailRules"
                    @keyup.enter.stop="validate"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="otp"
                    label="OTP code"
                    :rules="otpRules"
                    @keyup.enter.stop="validate"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="submitbtn px-0">
                  <v-btn
                    :disabled="!valid"
                    class="submitbutton btn-dark"
                    @click="validate"
                    v-if="!loading"
                  >
                    Verify
                  </v-btn>
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                  ></v-progress-circular>

                  <v-snackbar
                    v-model="snackbar"
                    v-if="allVerify.data != undefined"
                  >
                    {{ allVerify.data.message }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Verify",
  data() {
    return {
      allUserloading: false,
      valid: true,
      loading: false,
      email: "",
      otp: "",
      snackbar: false,
      showpassword: false,
      emailRules: [(v) => !!v || "Email is required"],
      otpRules: [(v) => !!v || "OTP code is required"],
    };
  },
  methods: {
    ...mapActions(["verify", "businessVerify"]),
    async validate() {
      this.loading = true;
      if (this.$route.params.slug == "user") {
        if (this.$refs.form.validate()) {
          let userData = {
            email: this.email,
            otp: this.otp,
          };
          await this.verify(userData);
          this.snackbar = true;
        }
      } else {
        if (this.$refs.form.validate()) {
          let userData = {
            email: this.email,
            otp: this.otp,
          };
          await this.businessVerify(userData);
          this.snackbar = true;
        }
      }
      this.loading = false;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    logout() {
      this.$store.dispatch("logoutUser");
    },
    backBtn() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["allLoadingAuth", "allVerify", "allBusinessVerify"]),
  },
  created() {
    this.snackbar = true;
    if (localStorage.getItem("emailAuth") != undefined) {
      this.email = localStorage.getItem("emailAuth");
    }
    if (localStorage.getItem("token") != undefined) {
      this.$router.push("/dashboard");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.boxauthoriation {
  padding: 15px 15px;
}
.message {
  padding: 0 00px 10px 10px;
  color: rgba(188, 188, 188, 1);
  font-size: 12px;
}
.registerlink {
  margin: 15px 0;
  font-size: 14px;
  .haveAccount {
    margin: 15px 0;
  }
  a {
    font-weight: 500;
  }
}

.submitbtn {
  text-align: center;
}
img {
  width: 150px;
}
.form-section {
  .col-md-6,
  .col-md-12 {
    padding: 0 20px !important;
  }
}
.titleform {
  margin-bottom: 20px;
}

.btn-dark {
  background: $btnColor !important;
  width: 95%;
  height: 50px !important;
}
</style>
